import React, { useEffect, useState } from 'react';
import '../styles/fonts.css';
import ScrollToTopButton from './scrollToTopButton';
import MegaMenuFixed from '../newComponents/megaMenuFixed';
import LoadingScreen from './loadingScreen';

export default function Layout({ children }) {
  const [_Loading, _setLoading] = useState(true)
  // const scrollable = document.getElementById('scrollable');
  // let isScrolling;
  // scrollable.addEventListener('scroll', () => {
  //   // Add class to show scrollbar
  //   scrollable.classList.add('show-scrollbar');

  //   // Clear our timeout throughout the scroll
  //   window.clearTimeout(isScrolling);

  //   // Set a timeout to run after scrolling ends
  //   isScrolling = setTimeout(() => {
  //     // Remove class after scrolling ends
  //     scrollable.classList.remove('show-scrollbar');
  //   }, 1000); // Adjust time as needed
  // });

  // useEffect(() => {
  //   const scrollable = document.getElementById('scrollable');
  //   let isScrolling;

  //   if (scrollable) {
  //     const handleScroll = () => {
  //       // Add class to show scrollbar
  //       scrollable.classList.add('show-scrollbar');

  //       // Clear our timeout throughout the scroll
  //       window.clearTimeout(isScrolling);

  //       // Set a timeout to run after scrolling ends
  //       isScrolling = setTimeout(() => {
  //         // Remove class after scrolling ends
  //         scrollable.classList.remove('show-scrollbar');
  //       }, 1000); // Adjust time as needed
  //     };

  //     scrollable.addEventListener('scroll', handleScroll);

  //     // Clean up the event listener on component unmount
  //     return () => {
  //       scrollable.removeEventListener('scroll', handleScroll);
  //     };
  //   }
  // }, []);

  return (
    <div id='scrollable' className=''>
      <MegaMenuFixed _setLoadingFalse={() =>_setLoading(false)} />
      {_Loading && <LoadingScreen />}
        {children}
      <ScrollToTopButton />
    </div>
  );
}
