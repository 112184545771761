import React from "react";
import "./loading.scss";
const LoadingScreen = (_props) => {
  return (
    <div>
      <div className='stage flex min-h-screen fixed  max-h-full justify-center items-center bg-[#2a3b8f] z-50  w-full'>
        <div className='dot1'></div>
        <div className='dot2'></div>
        <div className='dot3'></div>
      </div>
    </div>
  );
};

export default LoadingScreen;
