import './src/styles/global.css';
import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import store from './src/redux/store';
import { Router } from "@reach/router";

import Layout from './src/components/layout';

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
  profilesSampleRate: 1.0,
  tracesSampleRate: 1.0, // Adjust this value as needed (0.0 to 1.0)
});

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <Layout>{element}</Layout>
  </Provider>
);
